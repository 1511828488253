import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class BlockCustomerStratePremium {
    constructor() {
        if ($('[data-element="block-strate-premium"]').length > 0) {
            BlockCustomerStratePremium.buildSwiper()
        }
    }
    static async buildSwiper() {
        const element = $('[data-element="block-strate-premium"]')
        if (
            element.length > 0 &&
            !element.is(':hidden') &&
            !$('body').hasClass('eco-mode-active')
        ) {
            element.each(async (index) => {
                const swiperKey = 'strate-premium-swiper'
                const slideItem = `[data-swiper="${swiperKey}"] > .block__customer__strate-premium__slide`

                const options = {
                    mousewheel: {
                        forceToAxis: true,
                    },
                    slidesPerView: 1.1,
                    spaceBetween: 10,
                    speed: 1000,
                    navigation: {
                        nextEl: `.swiper-${swiperKey}-next`,
                        prevEl: `.swiper-${swiperKey}-prev`,
                    },
                    keyboard: true,
                    loop: false,
                    freeMode: {
                        enabled: true,
                    },
                    freeModeFluid: true,
                    passiveListeners: false,
                    watchSlidesVisibility: true,
                    breakpoints: {
                        600: {
                            slidesPerView: 1.8,
                            spaceBetween: 20,
                        },
                        1000: {
                            slidesPerView: 2.5,
                            spaceBetween: 40,
                        },
                    },
                }

                $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

                const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
            })
        }
    }
}

new BlockCustomerStratePremium()
